import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import {
  Box,
  Typography,
  Container,
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableBody,
  Table,
  TableCell
} from "@material-ui/core"
import { makeStyles, withStyles } from "@material-ui/core/styles"

function createData(name, purpose, pore, info) {
  return { name, purpose, pore, info }
}

const rows = [
  createData(
    "_ga",
    "Analiticas",
    "Externa (Google Analytics)",
    "Usada para diferenciar usuarios"
  ),
  createData(
    "_gat",
    "Analiticas",
    "Externa (Google Analytics)",
    "Limitar número de peticiones"
  ),
  createData(
    "_gid",
    "Analiticas",
    "Externa (Google Analytics)",
    "Usada para diferenciar usuarios"
  ),
  // createData(
  //   "iban ibanonlinecom cookienotice",
  //   "Internal",
  //   "Propio/a",
  //   "Aceptación de la política de cookies"
  // ),
  createData(
    "UserFrosting",
    "Auth/Session token",
    "Propio/a",
    "Identificar todos los requests pertenecientes a la misma sesión"
  ),
  createData(
    "1P_JAR",
    "Analytics",
    "Externa (Google Analytics)",
    "Establecer una identificación única para acceder servicios g suite"
  ),
  createData("_cfduid", "Internas", "Externas (jQuery)", "Needed by jQuery"),
  createData(
    "_cfduid",
    "Seguridad",
    "Externa (Cloudflare)",
    " Identificar clientes individuales, para recordar configuraciones del usuario"
  ),
  createData(
    "_biz_flasgA",
    "Seguridad",
    "Externa (Cloudflare)",
    "Identificar clientes individuales, recordar configuraciones del usuario"
  ),
  createData(
    "_biz_nA",
    "Seguridad",
    "Externa (Cloudflare)",
    "Identificar clientes individuales, recordar configuraciones del usuario"
  ),
  createData(
    "_biz_flasgA",
    "Seguridad",
    "Externa (Cloudflare)",
    "Identificar clientes individuales, recordar configuraciones del usuario"
  ),
  createData(
    "_biz_pendingA",
    "Seguridad",
    "Externa (Cloudflare)",
    " Identificar clientes individuales, recordar configuraciones del usuario"
  ),
  createData(
    "_biz_uid",
    "Seguridad",
    "Externa (Cloudflare)",
    "Identificar clientes individuales, recordar configuraciones del usuario"
  ),
  createData(
    "_mkto_trk",
    "Seguridad",
    "Externa (Cloudflare)",
    "Identificar clientes individuales, recordar configuraciones del usuario"
  )
]
const useStyles = makeStyles(theme => ({
  root: {},
  containerPart: {
    [theme.breakpoints.down("md")]: {
      marginTop: "6em"
    },
    marginTop: "10em"
  },
  linksStyles: {
    textDecoration: "underline",
    color: theme.palette.common.black,
    fontWeight: 500,
    "&:hover": {
      color: theme.palette.primary
    }
  },
  table: {
    minWidth: 700
  }
}))
const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell)
const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow)

export default function CookiesPolicy() {
  const classes = useStyles()
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CFL | Políticas de Cookies</title>
      </Helmet>
      <Container className={classes.containerPart}>
        <Box maxWidth="80%" m="auto">
          <Box mt={4} py={2}>
            <Typography variant="h1" component="h1" gutterBottom>
              <b>
                Capital Funding Lab <br />
                Politicas de Almacenamiento de Cookies
              </b>
            </Typography>
          </Box>
          <Box mb={4} py={2}>
            <Typography variant="h3" gutterBottom inline>
              Aviso sobre el almacenamiento de cookies
            </Typography>
            <Typography variant="body1" gutterBottom inline>
              Recopilamos datos sobre cómo interactúa con nuestro sitio web
              mediante el uso de cookies. Nuestro sitio web utiliza cookies para
              distinguirlo de otros usuarios de nuestro sitio web. Al continuar
              navegando por nuestro sitio web, acepta nuestro uso de cookies.
            </Typography>
            <Typography variant="body1" gutterBottom inline>
              Una cookie es un pequeño archivo de letras y números que
              almacenamos en su navegador o en el disco duro de su computadora
              si está de acuerdo. Las cookies contienen información que se
              transfiere al disco duro de su computadora.
            </Typography>
            <Typography variant="h3" gutterBottom inline>
              Utilizamos las siguientes cookies:
            </Typography>
            <Typography variant="body1" gutterBottom inline>
              <ul>
                <li>
                  <b>Cookies estrictamente necesarias.</b> Estas son cookies que
                  se requieren para el funcionamiento de nuestro sitio web.
                  Incluyen, por ejemplo, cookies que le permiten iniciar sesión
                  en áreas seguras de nuestro sitio web, utilizar un carrito de
                  compras o utilizar los servicios de facturación electrónica.
                </li>
                <li>
                  <b>Cookies analíticas / de rendimiento.</b> Nos permiten
                  reconocer y contar el número de visitantes y ver cómo los
                  visitantes se mueven por nuestro sitio web cuando lo usan.
                  Esto nos ayuda a mejorar la forma en que funciona nuestro
                  sitio web, por ejemplo, al garantizar que los usuarios
                  encuentren fácilmente lo que buscan.
                </li>
                <li>
                  <b>Cookies de funcionalidad.</b> Estos se utilizan para
                  reconocerlo cuando regresa a nuestro sitio web. Esto nos
                  permite personalizar nuestro contenido para usted, saludarlo
                  por su nombre y recordar sus preferencias (por ejemplo, su
                  elección de idioma o región).
                </li>
                <li>
                  <b>Cookies de orientación</b> Estas cookies registran su
                  visita a nuestro sitio web, las páginas que ha visitado y los
                  enlaces que ha seguido. Utilizaremos esta información para
                  hacer que nuestro sitio web y la publicidad que se muestra en
                  él sean más relevantes para sus intereses. También podemos
                  compartir esta información con terceros para este propósito.
                </li>
              </ul>
            </Typography>
          </Box>
          <Box py={2}>
            <Typography variant="body1" gutterBottom inline>
              Puede encontrar más información sobre las cookies individuales que
              utilizamos y los fines para los que las utilizamos en la siguiente
              tabla:
            </Typography>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Purpose</StyledTableCell>
                    <StyledTableCell>Propia/Externa</StyledTableCell>
                    <StyledTableCell>Información_Adicional</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map(row => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell component="th" scope="row">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell>{row.purpose}</StyledTableCell>
                      <StyledTableCell>{row.pore}</StyledTableCell>
                      <StyledTableCell>{row.info}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box py={2}>
            <Typography variant="h3" gutterBottom inline>
              Third parties
            </Typography>
            <Typography variant="body1" gutterBottom inline>
              Tenga en cuenta que terceros (incluidos, por ejemplo, redes de
              publicidad y proveedores de servicios externos como servicios de
              análisis de tráfico web) también pueden usar cookies, sobre las
              cuales no tenemos control. Es probable que estas cookies sean
              cookies analíticas / de rendimiento o cookies de orientación
            </Typography>
          </Box>
          <Box mb={4} py={2}>
            <Typography variant="h3" gutterBottom inline>
              ¿Como cambio mi configuración de cookies?
            </Typography>
            <Typography variant="body1" gutterBottom inline>
              Tenga en cuenta que terceros (incluidos, por ejemplo, redes de
              publicidad y proveedores de servicios externos como servicios de
              análisis de tráfico web) también pueden usar cookies, sobre las
              cuales no tenemos control. Es probable que estas cookies sean
              cookies analíticas / de rendimiento o cookies de orientación
            </Typography>
            <Typography variant="body1" gutterBottom inline>
              Puede encontrar más información sobre cookies en{" "}
              <a
                className={classes.linksStyles}
                href="http://www.allaboutcookies.org"
              >
                www.allaboutcookies.org
              </a>{" "}
              or{" "}
              <a
                className={classes.linksStyles}
                href="http://www.aboutcookies.org/"
              >
                www.aboutcookies.org
              </a>
            </Typography>
            <Typography variant="body1" gutterBottom inline>
              Para optar por no ser rastreado por Google Analytics en todos los
              sitios web, visite{" "}
              <a
                className={classes.linksStyles}
                href="http://tools.google.com/dlpage/gaoptout"
              >
                http://tools.google.com/dlpage/gaoptout
              </a>
            </Typography>
          </Box>
        </Box>
      </Container>
    </Layout>
  )
}
